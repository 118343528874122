import { hunter_post, hunter_post_form, post, dipper_post_form, cupid_post } from '../http'

export const oppoTradeDetail = (data) => {
    return hunter_post({ path: '/oppo/tradeDetail', data: { ...data } })
}

export const oppoFollowRecordCallHistory = (data) => {
    return hunter_post({ path: '/oppoFollowRecord/callHistory', data })
}

export const oppoAssignRuleAdd = (data) => {
    return hunter_post({ path: '/oppoAssignRule/add', data })
}

export const updateOppoAssignRule = (data) => {
    return hunter_post({ path: '/oppoAssignRule/put', data })
}

export const getOppoAssignRule = (data) => {
    return hunter_post({ path: '/oppoAssignRule/list', data })
}

export const oppoAssignRuleMod = (data) => {
    return hunter_post({ path: '/oppoAssignRule/mod', data })
}

export const oppoList = (data) => {
    return hunter_post({ path: '/oppo/list', data })
}
export const oppoMod = (data) => {
    return hunter_post({ path: '/oppo/mod', data })
}

export const oppoAssign = (data) => {
    return hunter_post({ path: '/oppo/assign', data })
}

export const getOppoList = (data) => {
    return hunter_post({ path: '/oppoConsole/list', data })
}
export const oppoUserWechat = (data) => {
    return hunter_post({ path: '/oppoConsole/userWechat', data, loading: { status: false } })
}

export const oppoFollowRecordList = (data) => {
    return hunter_post({ path: '/oppoFollowRecord/list', data, loading: { status: false } })
}
export const oppoFollowRecordAdd = (data) => {
    return hunter_post({ path: '/oppoFollowRecord/add', data })
}
export const oppoFollowRecordMod = (data) => {
    return hunter_post({ path: '/oppoFollowRecord/mod', data })
}
// 表单记录
export const trackFormRecord = (data) => {
    return hunter_post_form({ path: '/oppo/trackFormRecord', data })
}

export const oppoContactList = (data) => {
    return hunter_post({ path: '/oppoContact/list', data, loading: { status: false } })
}
export const oppoContactAdd = (data) => {
    return hunter_post({ path: '/oppoContact/add', data })
}

export const oppoContactMod = (data) => {
    return hunter_post({ path: '/oppoContact/mod', data })
}
export const oppoContactDel = (id) => {
    return hunter_post_form({ path: '/oppoContact/del', data: { id } })
}

// 配置学员联系方式
export const oppoWxFriendAdd = (data) => {
    return hunter_post({ path: '/oppoWxFriend/add', data })
}
export const oppoWxFriendMod = (data) => {
    return hunter_post({ path: '/oppoWxFriend/mod', data })
}
export const oppoWxFriendDel = (id) => {
    return hunter_post_form({ path: '/oppoWxFriend/del', data: { id } })
}

export const getHunterUserAttend = (data) => {
    return hunter_post({ path: '/hunterUser/attend', data })
}

export const getUserAttend = (data) => {
    return hunter_post({ path: '/hunterUser/attend', data })
}

export const getUserReceivePackage = (data) => {
    return hunter_post({ path: '/hunterUser/receivePackage', data })
}

export const getUserOrder = (data) => {
    return hunter_post({ path: '/hunterUser/tradeOrder', data })
}

export const getSurveyRecord = (data) => {
    return post({ path: '/questionSurvey/records', data })
}

// 查询意向列表
export const getIntentTypeList = (data) => {
    return hunter_post({ path: '/oppoIntent/task/list', data })
}

export const addAssTask = (data) => {
    return hunter_post({ path: '/assTask/add', data })
}

export const getMaterialConfig = (data) => {
    return cupid_post({ path: '/appMaterial/query', data })
}

export const getStaffAssOnline = (data) => {
    return dipper_post_form({ path: '/socket/assOnline', data })
}

export const updateAppMaterialConfig = (data) => {
    return cupid_post({ path: '/appMaterial/put', data })
}

// KOL 工作台接口
export const getKolBatchList = () => {
    return hunter_post({ path: '/kolConsole/batchList', data: {} })
}
export const getKolUserList = (data) => {
    return hunter_post({ path: '/kolConsole/list', data })
}

export const getBatchProcess = (data) => {
    return hunter_post({ path: '/kolConsole/batchProcess', data })
}

export const getHunterUserInfo = (data) => {
    return hunter_post({ path: '/hunterUser/list', data })
}

export const oppoWxFriendList = (data) => {
    return hunter_post({ path: '/oppoWxFriend/list', data, loading: { status: false } })
}

export const getOppoIntentList = (data) => {
    return hunter_post({ path: '/oppoIntent/list', data })
}

export const getOppoIntentRecords = (data) => {
    return hunter_post({ path: '/oppoIntent/queryRecords', data })
}

export const getOppoIntentDeals = (data) => {
    return hunter_post({ path: '/oppoIntent/queryDeals', data })
}

export const transferRecord = (data) => {
    return hunter_post({ path: '/oppoIntent/transfer', data })
}

export const addIntentDeal = (data) => {
    return hunter_post({ path: '/oppoIntent/deal', data })
}

export const addIntent = (data) => {
    return post({ path: '/app/intent/add', data })
}

export const getStaffIntent = (data) => {
    return hunter_post({ path: '/oppoConsole/overview/intent', data, loading: { status: false } })
}
export const getStaffAssign = (data) => {
    return hunter_post({ path: '/oppoConsole/overview/assign', data, loading: { status: false } })
}
