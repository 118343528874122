<template>
    <div class="page">
        <div class="left">
            <div class="card_container">
                <div class="label">今日流水:</div>
                <div class="value">
                    <el-statistic class="custom_statistic" :precision="2" :value="todayAmount / 100" prefix="¥" />
                </div>
                <div class="label">本月流水:</div>
                <div class="value">
                    <el-statistic class="custom_statistic" :precision="2" :value="monthAmount / 100" prefix="¥" />
                </div>
            </div>
            <!-- <div class="card_container">
                <div class="label">今日流水:</div>
                <div class="value">
                    <el-statistic class="custom_statistic" :precision="2" :value="todayAmount / 100" prefix="¥" />
                </div>
                <div class="label">本月流水:</div>
                <div class="value">
                    <el-statistic class="custom_statistic" :precision="2" :value="monthAmount / 100" prefix="¥" />
                </div>
            </div> -->
        </div>
        <div class="card_container charts_container" v-loading="chartLoading">
            <div class="chart" ref="mountNode"></div>
        </div>
    </div>
</template>
<script>
// import { getDaySummary } from '@/js/api/commonApi.js'
import dayjs from 'dayjs'

export default {
    data() {
        return {
            todayAmount: 123989500,
            monthAmount: 24412300,

            chartLoading: false,
        }
    },
    mounted() {
        this.getDaySummaryApi()
    },
    destroyed() {
        window.onresize = null
    },
    methods: {
        getDaySummaryApi() {
            const _dayNum = 29
            this.chartLoading = true
            const res = { data: [] }
            for (let index = 0; index < 30; index++) {
                res.data.push({
                    day: `2023-07-${index > 10 ? index : '0' + index}`,
                    tradeAmount: parseInt(Math.random() * 100),
                    tradeCnt: parseInt(Math.random() * 100),
                })
            }
            setTimeout(() => {
                this.chartLoading = false
            }, 400)
            const _dayList = []
            const _tradeAmountList = []
            const _tradeCntList = []
            for (let index = _dayNum; index >= 0; index--) {
                _dayList.push(dayjs().subtract(index, 'day').format('YYYY-MM-DD'))
                _tradeAmountList.push(0)
                _tradeCntList.push(0)
            }
            res.data.forEach((item) => {
                const _i = _dayList.indexOf(item.day)
                _tradeAmountList[_i] = item.tradeAmount / 100
                _tradeCntList[_i] = item.tradeCnt
            })
            const chart = window.echarts.init(this.$refs.mountNode)
            chart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: { type: 'cross', crossStyle: { color: '#999' } },
                },
                grid: { left: 10, right: 10, bottom: 10, containLabel: true },
                legend: { data: ['日流水', '交易笔数'] },
                xAxis: [{ type: 'category', data: _dayList }],
                yAxis: [
                    { type: 'value', min: 0, axisLabel: { formatter: '{value} 元' } },
                    { type: 'value', min: 0, axisLabel: { formatter: '{value} 笔' } },
                ],
                series: [
                    {
                        name: '日流水',
                        type: 'bar',
                        yAxisIndex: 0,
                        color: '#2ac978',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '元'
                            },
                        },
                        data: _tradeAmountList,
                    },
                    {
                        name: '交易笔数',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' 笔'
                            },
                        },
                        data: _tradeCntList,
                    },
                ],
            })
            window.onresize = chart.resize
        },
    },
}
</script>

<style lang="less" scoped>
.page {
    padding: 12px 0;
    display: flex;
    max-width: 1560px;
    margin: 0 auto;
    width: 100%;
}
.left {
    flex-shrink: 0;
    width: 300px;
    margin-right: 20px;
    .card_container {
        margin-bottom: 20px;
        .label {
            font-size: 14px;
            font-weight: 600;
            line-height: 2em;
            padding: 0 12px;
            color: #666;
        }
        /deep/ .value {
            line-height: 1.6em;
            color: #000;
            padding-left: 28px;
            margin: 12px 0 20px;
            font-weight: 600;
            font-size: 16px;
            .custom_statistic {
                width: auto !important;
                text-align: inherit !important;
                .con {
                    display: inline-block !important;
                    .number {
                        font-size: 18px !important;
                    }
                }
            }
        }
    }
}

.charts_container {
    flex-grow: 1;
    margin-bottom: 20px;
    .chart {
        height: 500px;
    }
}
</style>
<style lang="less" scoped>
@media screen and (max-width: 1400px) and (min-width: 900px) {
    .page {
        padding: 0;
        flex-direction: column;
    }
    .left {
        display: flex;
        flex-shrink: 0;
        width: 100%;
        margin-right: 0;
        justify-content: space-between;
        .card_container {
            width: 49%;
            margin-bottom: 20px;
        }
    }
    .charts_container {
        .chart {
            height: 400px;
        }
    }
}

@media screen and (max-width: 900px) {
    .page {
        padding: 0;
        flex-direction: column;
    }
    .left {
        flex-shrink: 0;
        width: 100%;
        margin-right: 0;
        justify-content: space-between;
        .card_container {
            width: 100%;
            margin-bottom: 20px;
        }
    }
    .charts_container {
        .chart {
            height: 300px;
        }
    }
}
</style>
