<template>
    <div>
        <AdminContainer v-if="role === 'admin'" />
        <SaleContainer v-else />
    </div>
</template>
<script>
import AdminContainer from './components/AdminContainer.vue'
import SaleContainer from './components/SaleContainer.vue'

export default {
    components: { AdminContainer, SaleContainer },
    data() {
        return {
            role: 'sale',
        }
    },
}
</script>
