<template>
    <div class="page">
        <div class="card_container ranking">
            <div>
                <ProjectSelect @change="onProjectNoChange" />
            </div>
            <div v-if="!userInfo.isAdmin">
                <span class="label">组内排名</span>
                <span class="value">{{ rankData && rankData.groupRanNo }}</span>
            </div>
            <div>
                <span class="label">本月流水</span>
                <span class="value">
                    <NumFormat type="PRICE" :num="rankData && rankData.feeSum" />
                </span>
            </div>
        </div>
        <div class="flex">
            <div class="other">
                <div class="card_container">
                    <div class="title">今日名片</div>
                    <div class="oppo_type">
                        <span @click="onTodayOppoTypeChange('new')" :class="todayOppoType === 'new' ? 'active' : ''">
                            新名片
                        </span>
                        <span
                            @click="onTodayOppoTypeChange('transfer')"
                            :class="todayOppoType === 'transfer' ? 'active' : ''"
                        >
                            离职交接
                        </span>
                        <span
                            @click="onTodayOppoTypeChange('repeat')"
                            :class="todayOppoType === 'repeat' ? 'active' : ''"
                        >
                            重复表单
                        </span>
                    </div>
                    <div class="task_info">
                        <div class="task_item" @click="toWorkbench('today', '1')">
                            <span class="value">{{ todayData.totalCnt }}</span>
                            <span class="label">应处理</span>
                        </div>
                        <div class="task_item" @click="toWorkbench('today', '2')">
                            <span class="value" :class="todayData.notDealCnt ? 'bold_value' : ''">
                                {{ todayData.notDealCnt }}
                            </span>
                            <span class="label">未处理</span>
                        </div>
                        <div class="task_item" @click="toWorkbench('today', '3')">
                            <span class="value">{{ todayData.applyCnt }}</span>
                            <span class="label">加微申请中</span>
                        </div>
                        <div class="task_item" @click="toWorkbench('today', '4')">
                            <span class="value">{{ todayData.friendCnt }}</span>
                            <span class="label">已加微信</span>
                        </div>
                    </div>
                </div>
                <div class="card_container">
                    <div class="title">意向</div>
                    <div class="task_info">
                        <div class="task_item" @click="toWorkbench('intent', 'LIVE_QA')">
                            <span class="value" :class="intentData.LIVE_QA ? 'bold_value' : ''">
                                {{ intentData.LIVE_QA }}
                            </span>
                            <span class="label">直播间</span>
                        </div>
                        <div class="task_item" @click="toWorkbench('intent', 'UNPAID')">
                            <span class="value" :class="intentData.UNPAID ? 'bold_value' : ''">
                                {{ intentData.UNPAID }}
                            </span>
                            <span class="label">下单未支付</span>
                        </div>
                        <div class="task_item" @click="toWorkbench('intent', 'DEPOSIT')">
                            <span class="value" :class="intentData.DEPOSIT ? 'bold_value' : ''">
                                {{ intentData.DEPOSIT }}
                            </span>
                            <span class="label">定金</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card_container order">
                <div class="title">本月流水详情</div>
                <el-table :data="tableData" border>
                    <el-table-column align="center" type="index" label="序号" width="72" />
                    <el-table-column align="center" prop="userId" label="学员ID" width="80" />
                    <el-table-column align="center" prop="nickname" label="昵称" width="120" />
                    <el-table-column align="center" prop="paidTime" label="报名时间" />
                    <el-table-column header-align="center" prop="productTitle" label="报名课程" />
                    <el-table-column align="center" prop="paidFeeText" label="成交金额" width="100">
                        <template slot-scope="scope">
                            <NumFormat :key="scope.row.id" type="PRICE" :num="scope.row.paidFee" />
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
import ProjectSelect from '@/views/components/ProjectSelect'
import { userTradeRank } from '@/js/api/commonApi.js'
import { getStaffIntent, getStaffAssign, oppoTradeDetail } from '@/js/api/saleApi.js'
import { filterQueryEmpty } from '@/js/utils.js'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
    computed: { ...mapGetters(['userInfo']) },
    components: { ProjectSelect },
    data() {
        return {
            projectNo: '',

            tableData: [],

            rankData: null,
            intentData: { UNPAID: 0, LIVE_QA: 0, DEPOSIT: 0, ATTEND: 0 },

            todayOppoType: '',
            todayData: { dealCnt: 0, notDealCnt: 0, friendCnt: 0, applyCnt: 0, totalCnt: 0 },
        }
    },
    methods: {
        onProjectNoChange(val) {
            this.projectNo = val
            this.userTradeRankApi()
            this.getStaffIntentApi()
            this.getStaffAssignApi()
            this.oppoTradeDetailApi()
        },

        onTodayOppoTypeChange(type) {
            if (this.todayOppoType === type) {
                this.todayOppoType = ''
            } else {
                this.todayOppoType = type
            }
            this.getStaffAssignApi()
        },
        toWorkbench(type, value) {
            const query = {}
            query[type] = value
            if (this.todayOppoType) {
                query.oppoType = this.todayOppoType
            }
            if (type === 'intent') {
                this.$router.push({ path: '/sale/workbench/intention', query: query })
            } else {
                this.$router.push({ path: '/sale/workbench/tel', query: query })
            }
        },

        userTradeRankApi() {
            userTradeRank({ staffId: this.userInfo.id, projectNo: this.projectNo }).then((res) => {
                this.rankData = { feeSum: 0, categoryRankNo: '-', groupRanNo: '-', ...res.data }
            })
        },
        getStaffAssignApi() {
            let params = { oppoType: this.todayOppoType, projectNo: this.projectNo }
            if (!this.userInfo.isAdmin) {
                params.staffIds = [this.userInfo.id]
            }
            params = filterQueryEmpty(params)
            getStaffAssign(params).then((res) => {
                const _d = { dealCnt: 0, notDealCnt: 0, friendCnt: 0, applyCnt: 0, totalCnt: 0, ...res.data[0] }
                _d.notDealCnt = _d.totalCnt - _d.dealCnt
                this.todayData = _d
            })
        },
        getStaffIntentApi() {
            getStaffIntent({
                staffIds: [this.userInfo.id],
                projectNo: this.projectNo,
                times: [
                    `${dayjs().subtract(1, 'day').format('YYYY-MM-DD')} 19:00:00`,
                    `${dayjs().format('YYYY-MM-DD')} 19:00:00`,
                ],
            }).then((res) => {
                const _d = { UNPAID: 0, LIVE_QA: 0, DEPOSIT: 0, ATTEND: 0 }
                res.data.forEach((item) => {
                    _d[item.type] = item.totalCnt - (item.dealCnt || 0)
                })
                this.intentData = _d
            })
        },

        async oppoTradeDetailApi() {
            const res1 = await oppoTradeDetail({
                page: 1,
                showPage: false,
                staffId: this.userInfo.id,
                orderBy: '-paidTime',
                startTime: `${dayjs().startOf('month').format('YYYY-MM-DD')} 00:00:00`,
                endTime: `${dayjs().endOf('month').format('YYYY-MM-DD')} 23:59:59`,
                projectNo: this.projectNo,
            })
            // if (res1.data.length !== 0) {
            //     const _userIds = []
            //     res1.data.forEach((item) => {
            //         if (!_userIds.includes(item.userId)) {
            //             _userIds.push(item.userId)
            //         }
            //     })
            //     const res2 = await userList({ ids: _userIds })
            //     const _userMap = {}
            //     res2.data.forEach((i) => (_userMap[i.id] = i))
            //     res1.data.forEach((item) => {
            //         item.userInfo = _userMap[item.userId]
            //     })
            // }
            this.tableData = res1.data
        },
    },
}
</script>

<style lang="less" scoped>
.page {
    min-width: 800px;
    margin: 0 auto;
}
.flex {
    flex-shrink: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .order {
        width: 60%;
    }
    .other {
        width: 39%;
        .card_container {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.card_container {
    position: relative;
    padding: 16px;
    .title {
        font-size: 14px;
        font-weight: 600;
        line-height: 1em;
        margin-bottom: 16px;
        color: #666;
    }

    .oppo_type {
        position: absolute;
        right: 16px;
        top: 16px;
        span {
            font-size: 12px;
            margin: 0 10px;
            font-weight: 400;
            cursor: pointer;
        }
        .active {
            color: #ed284c;
        }
    }
}
.ranking {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    > div {
        min-width: 200px;
        display: flex;
        align-items: center;
        padding: 12px;
        .label {
            flex-shrink: 0;
            font-size: 16px;
            color: #333333;
            margin-right: 12px;
            line-height: 1.5em;
        }
        .value {
            flex-shrink: 0;
            color: #ed284c;
            font-size: 22px;
            font-weight: 600;
        }
    }
}

.task_info {
    flex-grow: 1;
    padding: 12px 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    .task_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .value {
        font-size: 32px;
        margin-bottom: 20px;
        text-decoration: underline;
    }
    .bold_value {
        color: #ed284c;
    }
}
</style>
<style lang="less" scoped>
@media screen and (max-width: 1300px) {
    .flex {
        flex-direction: column;
        .other,
        .card_container {
            width: 100%;
            flex-shrink: 0;
            margin-bottom: 20px;
        }
    }
}
</style>
